// extracted by mini-css-extract-plugin
export var bestseller = "product-card-module--bestseller--f0a6f";
export var cartButton = "product-card-module--cart-button--3ee33";
export var disabled = "product-card-module--disabled--6b5d0";
export var discountProduct = "product-card-module--discount-product--30b30";
export var heading = "product-card-module--heading--4bbff";
export var headingText = "product-card-module--heading-text--b3919";
export var image = "product-card-module--image--ec0b3";
export var labels = "product-card-module--labels--212a5";
export var manufacturer = "product-card-module--manufacturer--c7dd9";
export var newProduct = "product-card-module--new-product--b1f1c";
export var price = "product-card-module--price--1e08f";
export var ratio = "product-card-module--ratio--cb2ef";
export var unavailable = "product-card-module--unavailable--1d9b7";
export var unavailableLabel = "product-card-module--unavailable-label--c9304";
export var wrapper = "product-card-module--wrapper--2bcb5";